import clients from "./clients";
var pageHeader = {
    image: "Banner/banner-03.png",
    title: "Clients",
    text: '"Client success is at the heart of our business. We are dedicated to delivering exceptional solutions that drive growth, transform businesses, and exceed client expectations."'
};
var sectionOne = {
    title: "Discover the <span>Businesses<span> We Proudly Serve",
    items: clients
};
var sectionTwo = {
    title: "Clients Benefits",
    subTitle: "Unlock the <span>Advantages of Partnering<span> with Us",
    items: [
        {
            title: "Tailored Solutions",
            text: "We understand that every client is unique, and we provide customized solutions that meet your specific needs and goals.",
            icon: "Icons/solution.png"
        },
        {
            title: "Expert Guidance",
            text: " Our team of experienced professionals is dedicated to providing expert guidance and strategic insights to help you make informed decisions and achieve optimal outcomes.",
            icon: "Icons/guidance.png"
        },
        {
            title: "Innovative Technologies",
            text: "We leverage the latest technologies, including React, ASP.NET, TypeScript, SQL Server, and Crystal Report, to deliver cutting-edge solutions that drive efficiency and innovation in your business.",
            icon: "Icons/technology.png"
        },
        {
            title: "Timely Support",
            text: "We pride ourselves on our responsive and proactive support. Our dedicated support team is always ready to assist you, ensuring smooth operations and minimal downtime.",
            icon: "Icons/support.png"
        },
        {
            title: "Continuous Improvement",
            text: "We believe in continuous improvement and stay up-to-date with the latest industry trends and best practices. As our client, you benefit from our commitment to delivering innovative and industry-leading solutions.",
            icon: "Icons/improvement.png"
        },
        {
            title: "Partnership Approach",
            text: "We value our client relationships and foster a collaborative partnership approach. We work closely with you, listening to your feedback and incorporating your insights into our solutions.",
            icon: "Icons/collaboration.png"
        },
    ]
};
var sectionThree = {
    title: "Customer Testimonials",
    subTitle: "Hear What Our <span>Clients</span> Have to Say",
    items: [
        {
            title: "Syed Zeeshan Haider",
            subTitle: "Director, ITT Foods (Pvt.) Ltd.",
            text: "As the Director of ITT Foods, the implementation of Businessflo ERP has brought positive changes to our supply chain, manufacturing, and finance processes. The incorporation of the workflow feature has facilitated a shift to a paperless environment and improved decision-making efficiency. This transformation has resulted in enhanced efficiency and reduced costs. Businessflo ERP is user-friendly interface, adding value to our organization.",
            image: "Customer/customer-1.jpg"
        },
        {
            title: "Naveed Shaheen",
            subTitle: "CFO & Corporate Secretary, VSolar (Pvt.) Ltd.",
            text: "As CFO & Corporate Secretary at Vitol Solar Pakistan , Businessflo ERP has had a significant impact on our operations and financial controls. The project accounting functionality, inventory visibility,  real-time finance revenue reporting , and the implementation of an approval flow have greatly improved our efficiency. The best part is that it has made our work easier and our environment paperless, resulting in a more streamlined and eco-friendly approach.",
            image: "Customer/customer-2.jpg"
        },
        {
            title: "Saeed Jamal Tariq",
            subTitle: "Al Umeed Rehabilitation Association (AURA)",
            text: "Businessflo ERP has significantly enhanced our financial control at Aura Foundation. Being a non-profit organization, our main focus is to promptly record financial transactions to have a clear overview of our inflows and outflows. It enables us to make informed decisions and manage expenses effectively. The software's user-friendly interface and ease of understanding have simplified our financial management processes. Overall, it has had a remarkable positive impact on our organization.",
            image: "Customer/customer-3.jpg"
        },
        {
            title: "Syed Iqbal Rafat",
            subTitle: "CEO & Managing Director, Sitech Pharmaceuticals (Pvt.) Ltd.",
            text: "As SiTech Pharmaceuticals' Chief Executive Officer, Businessflo ERP has transformed our supply chain and financial management. It optimized operations, inventory levels, and customer fulfillment. Real-time visibility boosted efficiency, reduced costs, and minimized stockouts. The finance module delivered revenue insights, driving smarter decisions and overall growth. Businessflo ERP is a game-changer for operational excellence and financial success.",
            image: "Customer/customer-4.jpg"
        },
        {
            title: "Muhammed Ausaf Ali",
            subTitle: "Head of Finance, Sitech Pharmaceuticals (Pvt.) Ltd.",
            text: "As SiTech Pharmaceuticals' Finance Manager, I'm thrilled with the transformative impact of Businessflo ERP on our financial management. Real-time visibility and robust features have improved our decision-making, driving financial success. Streamlined operations, accurate data, and compliance have enhanced efficiency and accuracy. Businessflo ERP is an invaluable tool for financial excellence.",
            image: "Customer/customer-5.jpg"
        }
    ]
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var clientsPage = {
    pageHeader: pageHeader,
    sectionOne: sectionOne,
    sectionTwo: sectionTwo,
    sectionThree: sectionThree,
    callToActionSection: callToActionSection
};
export default clientsPage;
