var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { themeGet, device } from "@/Theme";
export var ConatactWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    isolation: isolate;\n    padding-block: 80px;\n    background-color: #eff2f6;\n    \n    ", " {\n        padding-block: 100px;\n    }\n    ", " {\n        padding-block: 120px;\n    }\n"], ["\n    position: relative;\n    isolation: isolate;\n    padding-block: 80px;\n    background-color: #eff2f6;\n    \n    ", " {\n        padding-block: 100px;\n    }\n    ", " {\n        padding-block: 120px;\n    }\n"])), device.medium, device.large);
export var StyledBG = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    inset: 0;\n    z-index: -1;\n"], ["\n    position: absolute;\n    inset: 0;\n    z-index: -1;\n"])));
export var ContactInfoBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-align: center;\n    margin-block-start: 45px;\n    .icon {\n        color: ", ";\n        font-size: 40px;\n    }\n    ", " {\n        margin-block-start: 0;\n    }\n"], ["\n    text-align: center;\n    margin-block-start: 45px;\n    .icon {\n        color: ", ";\n        font-size: 40px;\n    }\n    ", " {\n        margin-block-start: 0;\n    }\n"])), themeGet("colors.primary"), device.large);
export var StyledHeading = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: 600;\n    position: relative;\n    line-height: 1.4;\n    padding-inline-start: 34px;\n    span {\n        color: ", ";\n    }\n    &:before {\n        content: \"\";\n        position: absolute;\n        top: 50%;\n        left: 0px;\n        width: 4px;\n        height: 94%;\n        transform: translateY(-50%);\n        background-color: ", ";\n    }\n"], ["\n    font-weight: 600;\n    position: relative;\n    line-height: 1.4;\n    padding-inline-start: 34px;\n    span {\n        color: ", ";\n    }\n    &:before {\n        content: \"\";\n        position: absolute;\n        top: 50%;\n        left: 0px;\n        width: 4px;\n        height: 94%;\n        transform: translateY(-50%);\n        background-color: ", ";\n    }\n"])), themeGet("colors.primary"), themeGet("colors.secondary"));
export var StyledText = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-block-start: 15px;\n    font-size: 18px;\n    margin-inline-start: 34px;\n"], ["\n    margin-block-start: 15px;\n    font-size: 18px;\n    margin-inline-start: 34px;\n"])));
export var StyledInfoTitle = styled.h6(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 15px;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n    margin-block-end: 10px;\n    margin-block-start: 10px;\n"], ["\n    font-size: 15px;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n    margin-block-end: 10px;\n    margin-block-start: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
