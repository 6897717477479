var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Anchor from "@/Components/UI/Anchor";
import Button from "@/Components/UI/Button";
import { ConatactWrapper, ContactInfoBox, StyledHeading, StyledText, StyledInfoTitle, StyledBG, } from "./style";
import Image from "../UI/Image";
var ContactArea = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, contactTitle = data.contactTitle, contactText = data.contactText, buttons = data.buttons;
    return (_jsxs(ConatactWrapper, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/contact-bg.jpg", alt: "Contact BG" }) }), _jsx(Container, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsxs(Col, __assign({ lg: 6 }, { children: [title && (_jsx(StyledHeading, { as: "h3", dangerouslySetInnerHTML: {
                                        __html: title
                                    } })), subTitle && (_jsx(StyledText, { dangerouslySetInnerHTML: {
                                        __html: subTitle,
                                    } }))] })), _jsx(Col, __assign({ lg: 6 }, { children: _jsxs(ContactInfoBox, { children: [_jsx("i", { className: "icon fas fa-phone" }), contactTitle && (_jsx(StyledInfoTitle, __assign({ as: "h6" }, { children: contactTitle }))), contactText && (_jsx("h2", { children: _jsx(Anchor, __assign({ path: "/", color: "#002fa6", hover: { layout: 2, color: "#002fa6" } }, { children: contactText })) })), buttons === null || buttons === void 0 ? void 0 : buttons.map(function (_a, idx) {
                                        var content = _a.content, path = _a.path, rest = __rest(_a, ["content", "path"]);
                                        return (_jsx(Button, __assign({ path: path, mt: "20px", minWidth: "230px" }, rest, { children: content }), idx));
                                    })] }) }))] })) })] }));
};
export default ContactArea;
