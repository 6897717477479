var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { device, themeGet } from "@/Theme";
export var HeroWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    isolation: isolate;\n    z-index: 1;\n    padding-block: 60px;\n    background-color: #000;\n    ", " {\n        padding-block: 80px;\n    }\n    ", " {\n        padding-block: 120px;\n    }\n"], ["\n    position: relative;\n    isolation: isolate;\n    z-index: 1;\n    padding-block: 60px;\n    background-color: #000;\n    ", " {\n        padding-block: 80px;\n    }\n    ", " {\n        padding-block: 120px;\n    }\n"])), device.medium, device.large);
export var StyledBG = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    inset: 0;\n    z-index: -1;\n    \n    img {\n        width: 100%;\n        height: 100%;\n    }\n"], ["\n    position: absolute;\n    inset: 0;\n    z-index: -1;\n    \n    img {\n        width: 100%;\n        height: 100%;\n    }\n"])));
export var HeroTextWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    text-align: center;\n    margin-block-end: 60px;\n    ", " {\n        text-align: left;\n        margin-block-end: 0;\n    }\n"], ["\n    text-align: center;\n    margin-block-end: 60px;\n    ", " {\n        text-align: left;\n        margin-block-end: 0;\n    }\n"])), device.medium);
export var HeroButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    ", " {\n        justify-content: flex-start;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    ", " {\n        justify-content: flex-start;\n    }\n"])), device.medium);
export var AppointmentWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    max-width: 400px;\n    background: #fff;\n    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);\n    border-radius: ", ";\n    text-align: center;\n    margin-inline-start: auto;\n    margin-inline-end: auto;\n    padding-block-start: 42px;\n    padding-block-end: 51px;\n    padding-inline-start: 30px;\n    padding-inline-end: 30px;\n"], ["\n    max-width: 400px;\n    background: #fff;\n    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);\n    border-radius: ", ";\n    text-align: center;\n    margin-inline-start: auto;\n    margin-inline-end: auto;\n    padding-block-start: 42px;\n    padding-block-end: 51px;\n    padding-inline-start: 30px;\n    padding-inline-end: 30px;\n"])), themeGet("radii.md"));
export var AppointmentTitleWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-block-end: 40px;\n    text-align: center;\n"], ["\n    margin-block-end: 40px;\n    text-align: center;\n"])));
export var AppointmentFormWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    text-align: start;\n"], ["\n    text-align: start;\n"])));
export var StyledSubtitle = styled.h6(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    color: #fff;\n    margin-block-end: 8px;\n"], ["\n    color: #fff;\n    margin-block-end: 8px;\n"])));
export var StyledTitle = styled.h1(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-weight: 500;\n    color: #fff;\n    margin-block-end: 10px;\n"], ["\n    font-weight: 500;\n    color: #fff;\n    margin-block-end: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
