import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from "@/Components/UI/Wrapper";
import SectionTitle from "@/Components/UI/SectionTitle";
import { SectionWrap } from "./style";
var AboutAreaOne = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, text = data.text;
    return (_jsx(SectionWrap, { children: _jsx(Container, { children: title && (_jsx(SectionTitle, { subtitle: subTitle, text: text })) }) }));
};
export default AboutAreaOne;
