var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { FormGroup, Input, Textarea } from "@/Components/UI/FormElements";
import { hasKey } from "@/Utils";
import Button from "@/Components/UI/Button";
var AppointmentForm = function () {
    var _a, _b, _c;
    var formUrl = "https://getform.io/f/1022fe2a-f195-4de6-a394-a9cc1ccd6a22";
    var _d = useForm(), register = _d.register, handleSubmit = _d.handleSubmit, errors = _d.formState.errors;
    var _e = __read(useState({
        submitting: false,
        status: null,
    }), 2), serverState = _e[0], setServerState = _e[1];
    var handleServerResponse = function (ok, msg, form) {
        setServerState({
            submitting: false,
            status: { ok: ok, msg: msg },
        });
        if (ok) {
            form.reset();
        }
    };
    var onSubmit = function (data, e) {
        var form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: formUrl,
            data: data,
        })
            .then(function (r) {
            handleServerResponse(true, "Thanks! for contacting with us", form);
        })
            .catch(function (r) {
            handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (_jsxs("form", __assign({ className: "appointment-form", onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsx(Input, __assign({ type: "text", id: "name", placeholder: "Name *", feedbackText: (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message, state: hasKey(errors, "name") ? "error" : "success", showState: !!hasKey(errors, "name") }, register("name", {
                    required: "Name is required",
                }))) })), _jsx(FormGroup, __assign({ mb: "20px" }, { children: _jsx(Input, __assign({ type: "email", id: "email", placeholder: "Email *", feedbackText: (_b = errors === null || errors === void 0 ? void 0 : errors.email) === null || _b === void 0 ? void 0 : _b.message, state: hasKey(errors, "email") ? "error" : "success", showState: !!hasKey(errors, "email") }, register("email", {
                    required: "Email is required",
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address",
                    },
                }))) })), _jsx(FormGroup, __assign({ mb: "30px" }, { children: _jsx(Textarea, __assign({ id: "message", placeholder: "Please describe what you need. *", feedbackText: (_c = errors === null || errors === void 0 ? void 0 : errors.message) === null || _c === void 0 ? void 0 : _c.message, state: hasKey(errors, "message") ? "error" : "success", showState: !!hasKey(errors, "message") }, register("message", {
                    required: "Message is required",
                    maxLength: {
                        value: 50,
                        message: "Maximum length is 50",
                    },
                    minLength: {
                        value: 10,
                        message: "Minimum length is 10",
                    },
                }))) })), _jsx(Button, __assign({ type: "submit", disabled: serverState.submitting }, { children: "Send Message" })), serverState.status && (_jsx("p", __assign({ className: "form-output ".concat(!serverState.status.ok ? "errorMsg" : "success") }, { children: serverState.status.msg })))] })));
};
export default AppointmentForm;
