var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import SwiperSlider, { SwiperSlide } from "@/Components/UI/Swiper";
import ClientLogo from "@/Components/UI/ClientLogo";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import { SectionWrap } from "./style";
import { clients } from "@/Data";
import SectionTitle from "@/Components/UI/SectionTitle";
var slider = {
    slidesPerView: 6,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: true,
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        575: {
            slidesPerView: 3,
        },
        767: {
            slidesPerView: 4,
        },
        991: {
            slidesPerView: 5,
        },
        1499: {
            slidesPerView: 6,
        },
    },
};
var ClientAreaOne = function () {
    return (_jsx(SectionWrap, { children: _jsx(Container, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ lg: 12, className: "mb-3" }, { children: _jsx(SectionTitle
                        // title={"OUR Clients"}
                        , { 
                            // title={"OUR Clients"}
                            subtitle: "Trusted by <span>Companies</span>, Proven by Results." }) })), _jsx(Col, __assign({ lg: 12 }, { children: _jsx(SwiperSlider, __assign({ options: slider, vAlign: "center" }, { children: clients.map(function (client, i) {
                                return (_jsx(SwiperSlide, __assign({ className: "item" }, { children: _jsx(ClientLogo, { image: client.image, title: client.title, layout: 4 }) }), i));
                            }) })) }))] }) }) }));
};
export default ClientAreaOne;
