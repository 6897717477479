var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Image from "@/Components/UI/Image";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Button from "@/Components/UI/Button";
import { StyledSection, StyledButtonWrap, StyledBG, StyledHeading, StyledText, StyledInfoTitle, ContentBox, } from "./style";
var SoftwareTechnologyOne = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, text = data.text, buttons = data.buttons, image = data.image;
    return (_jsxs(StyledSection, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/soft-s5-bg.webp", alt: "contact BG" }) }), _jsx(Container, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ lg: 6, mb: ["50px", null, null, 0] }, { children: _jsxs(ContentBox, { children: [title && (_jsx(StyledInfoTitle, __assign({ as: "h6" }, { children: title }))), subTitle && (_jsx(StyledHeading, { as: "h3", dangerouslySetInnerHTML: {
                                            __html: subTitle,
                                        } })), text && (_jsx(StyledText, { dangerouslySetInnerHTML: {
                                            __html: text,
                                        } })), _jsx(StyledButtonWrap, { children: buttons === null || buttons === void 0 ? void 0 : buttons.map(function (button, idx) { return (_jsx(Button, __assign({ variant: "outlined", color: "light", path: button.path }, { children: button === null || button === void 0 ? void 0 : button.content }), idx)); }) })] }) })), _jsx(Col, __assign({ lg: 6 }, { children: image && (_jsx(Image, { src: image, alt: "Hero" })) }))] }) })] }));
};
export default SoftwareTechnologyOne;
