var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { device, css } from "@/Theme";
export var SectionWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", "\n\n    ", "\n    ", "\n    .box-item {\n        margin-block-end: 50px;\n    }\n"], ["\n    ", "\n\n    ", "\n    ", "\n    .box-item {\n        margin-block-end: 50px;\n    }\n"])), function (_a) {
    var $background = _a.$background;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        background-color: ", ";\n    "], ["\n        background-color: ", ";\n    "])), $background);
}, function (_a) {
    var $space = _a.$space;
    return $space === 1 && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            padding-block-start: 60px;\n            padding-block-end: 60px;\n            ", " {\n                padding-block-end: 80px;\n            }\n            ", " {\n                padding-block-start: 100px;\n                padding-block-end: 100px;\n            }\n        "], ["\n            padding-block-start: 60px;\n            padding-block-end: 60px;\n            ", " {\n                padding-block-end: 80px;\n            }\n            ", " {\n                padding-block-start: 100px;\n                padding-block-end: 100px;\n            }\n        "])), device.medium, device.large);
}, function (_a) {
    var $space = _a.$space;
    return $space === 2 && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            padding-block: 60px;\n            ", " {\n                padding-block: 80px;\n            }\n            ", " {\n                padding-block: 100px;\n            }\n        "], ["\n            padding-block: 60px;\n            ", " {\n                padding-block: 80px;\n            }\n            ", " {\n                padding-block: 100px;\n            }\n        "])), device.medium, device.large);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
