var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, space, flexbox } from "@/Theme";
export var LogoWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    ", ";\n    ", ";\n    a {\n        img {\n            width: 112.5px;\n        }\n    }\n\n    ", "\n    ", "\n"], ["\n    display: flex;\n    ", ";\n    ", ";\n    a {\n        img {\n            width: 112.5px;\n        }\n    }\n\n    ", "\n    ", "\n"])), flexbox, space, function (_a) {
    var $variant = _a.$variant;
    return $variant === "white" && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            .dark-logo {\n                display: none !important;\n            }\n            .light-logo {\n                display: inherit;\n            }\n        "], ["\n            .dark-logo {\n                display: none !important;\n            }\n            .light-logo {\n                display: inherit;\n            }\n        "])));
}, function (_a) {
    var $variant = _a.$variant;
    return $variant === "dark" && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            .dark-logo {\n                display: inherit;\n            }\n            .light-logo {\n                display: none !important;\n            }\n        "], ["\n            .dark-logo {\n                display: inherit;\n            }\n            .light-logo {\n                display: none !important;\n            }\n        "])));
});
var templateObject_1, templateObject_2, templateObject_3;
