var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { about } from "@/Data";
import Seo from '@/Components/Seo';
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import { PageHeaderOne } from "@/Components/PageHeader";
import { AboutAreaTwo } from '@/Components/About';
import { ServiceAreaOne } from '@/Components/Service';
import { CallToActionTwo, CallToActionOne } from '@/Components/CallToAction';
import { BoxIconSectionOne, BoxIconSectionTwo } from "@/Components/BoxIconSection";
import { SoftwareTechnologyOne } from "@/Components/SoftwareTechnology";
import serviceSection from '@/Data/services';
var About = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: "About" }), _jsx(Header, {}), _jsxs("main", __assign({ className: "site-wrapper-reveal" }, { children: [_jsx(PageHeaderOne, { data: about.pageHeader }), _jsx(AboutAreaTwo, { data: about.aboutSection }), _jsx(ServiceAreaOne, { data: serviceSection }), _jsx(CallToActionTwo, { data: about.callToActionSectionOne }), _jsx(BoxIconSectionOne, { data: about.sectionOne }), _jsx(SoftwareTechnologyOne, { data: about.sectionTwo }), _jsx(BoxIconSectionTwo, { data: about.sectionThree }), _jsx(CallToActionOne, { data: about.callToActionSectionTwo })] })), _jsx(Footer, {})] }));
};
export default About;
