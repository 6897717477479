var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Text from "@/Components/UI/Text";
import Button from "@/Components/UI/Button";
import SectionTitle from "@/Components/UI/SectionTitle";
import MarkHeading from "@/Components/MarkHeading";
import { SectionWrap } from "./style";
var AboutAreaTwo = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, text = data.text, aboutText = data.aboutText, buttons = data.buttons;
    return (_jsx(SectionWrap, { children: _jsxs(Container, { children: [title && (_jsx(SectionTitle, { mb: ["47px", null, "60px"], title: title, subtitle: subTitle })), _jsxs(Row, { children: [_jsx(Col, __assign({ lg: { span: 4, offset: 1 }, mb: ["10px", null, null, 0] }, { children: aboutText && (_jsx(MarkHeading, __assign({ mr: "48px", as: "h2" }, { children: aboutText }))) })), _jsxs(Col, __assign({ lg: { span: 5, offset: 1 } }, { children: [text && (_jsx(Text, { fontSize: "18px", mb: "20px", dangerouslySetInnerHTML: { __html: text } })), buttons === null || buttons === void 0 ? void 0 : buttons.map(function (_a, idx) {
                                    var path = _a.path, content = _a.content, rest = __rest(_a, ["path", "content"]);
                                    return (_jsx(Button, __assign({ path: path, fontSize: "15px" }, rest, { children: content }), idx));
                                })] }))] })] }) }));
};
export default AboutAreaTwo;
