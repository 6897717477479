var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, themeGet, space } from "@/Theme";
import Anchor from "@/Components/UI/Anchor";
export var BoxLargeImgInner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: #fff;\n    border-radius: ", ";\n    overflow: hidden;\n    position: relative;\n    border-radius: 8px;\n    overflow: hidden;\n    position: relative;\n    padding: 98px 0 8px;\n    height: 100%;\n\n    ", "\n"], ["\n    background: #fff;\n    border-radius: ", ";\n    overflow: hidden;\n    position: relative;\n    border-radius: 8px;\n    overflow: hidden;\n    position: relative;\n    padding: 98px 0 8px;\n    height: 100%;\n\n    ", "\n"])), themeGet("radii.md"), function (props) {
    return props.$layout === 3 && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            background-color: ", ";\n        "], ["\n            background-color: ", ";\n        "])), themeGet("colors.primary"));
});
export var BoxLargeImgMedia = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    text-align: center;\n    width: 162px;\n    height: 162px;\n    border-radius: 50%;\n    margin: 0 auto 2px;\n    &::before {\n        background-image: -webkit-linear-gradient(\n            290deg,\n            #fbf7f4 0%,\n            #fefdfd 100%\n        );\n        background-image: -o-linear-gradient(290deg, #fbf7f4 0%, #fefdfd 100%);\n        background-image: linear-gradient(160deg, #fbf7f4 0%, #fefdfd 100%);\n        border-radius: 50%;\n        transform: translateY(-46px);\n        content: \"\";\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n    }\n"], ["\n    position: relative;\n    text-align: center;\n    width: 162px;\n    height: 162px;\n    border-radius: 50%;\n    margin: 0 auto 2px;\n    &::before {\n        background-image: -webkit-linear-gradient(\n            290deg,\n            #fbf7f4 0%,\n            #fefdfd 100%\n        );\n        background-image: -o-linear-gradient(290deg, #fbf7f4 0%, #fefdfd 100%);\n        background-image: linear-gradient(160deg, #fbf7f4 0%, #fefdfd 100%);\n        border-radius: 50%;\n        transform: translateY(-46px);\n        content: \"\";\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n    }\n"])));
export var BoxLargeImgContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    ", "\n    ", "\n"], ["\n    ", "\n    ", "\n"])), function (props) {
    return props.$layout !== 2 && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            padding-block-end: 88px;\n            padding-inline-start: 30px;\n            padding-inline-end: 30px;\n            text-align: center;\n        "], ["\n            padding-block-end: 88px;\n            padding-inline-start: 30px;\n            padding-inline-end: 30px;\n            text-align: center;\n        "])));
}, function (props) {
    return props.$layout === 2 && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            padding-block-start: 25px;\n            padding-block-end: 88px;\n            padding-inline-start: 26px;\n            padding-inline-end: 26px;\n            text-align: start;\n        "], ["\n            padding-block-start: 25px;\n            padding-block-end: 88px;\n            padding-inline-start: 26px;\n            padding-inline-end: 26px;\n            text-align: start;\n        "])));
});
export var HeadingWrap = styled.h2(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    line-height: 1.25;\n    ", "\n    ", "\n\n    ", "\n"], ["\n    line-height: 1.25;\n    ", "\n    ", "\n\n    ", "\n"])), function (props) {
    return props.$layout !== 2 && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            font-weight: 500;\n            margin-block-end: 12px;\n        "], ["\n            font-weight: 500;\n            margin-block-end: 12px;\n        "])));
}, function (props) {
    return props.$layout === 2 && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n            font-weight: 600;\n            margin-block-end: 2px;\n            font-size: 18px;\n        "], ["\n            font-weight: 600;\n            margin-block-end: 2px;\n            font-size: 18px;\n        "])));
}, function (props) {
    return props.$layout === 3 && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n           color: #fff;\n        "], ["\n           color: #fff;\n        "])));
});
export var CategoryWrap = styled.p(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    color: ", ";\n    margin-block-end: 2px;\n"], ["\n    color: ", ";\n    margin-block-end: 2px;\n"])), themeGet("colors.primary"));
export var TextWrap = styled.p(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    color: ", ";\n    ", "\n\n    ", "\n"], ["\n    color: ", ";\n    ", "\n\n    ", "\n"])), themeGet("colors.text"), function (props) {
    return props.$layout === 2 && css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n            margin-block-start: 13px;\n            margin-block-end: 0;\n        "], ["\n            margin-block-start: 13px;\n            margin-block-end: 0;\n        "])));
}, function (props) {
    return props.$layout === 3 && css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n            color: #fff;\n        "], ["\n            color: #fff;\n        "])));
});
export var BoxLargeImgBtnWrap = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 1;\n    height: 56px;\n    line-height: 55px;\n    text-align: center;\n    font-size: 14px;\n    font-weight: 500;\n    border-block-start: 1px solid ", ";\n    color: ", ";\n    &:before {\n        background-color: ", ";\n        content: \"\";\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        transform: translateY(100%);\n        transition: ", ";\n        z-index: -1;\n    }\n    \n    ", "\n"], ["\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 1;\n    height: 56px;\n    line-height: 55px;\n    text-align: center;\n    font-size: 14px;\n    font-weight: 500;\n    border-block-start: 1px solid ", ";\n    color: ", ";\n    &:before {\n        background-color: ", ";\n        content: \"\";\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        transform: translateY(100%);\n        transition: ", ";\n        z-index: -1;\n    }\n    \n    ", "\n"])), themeGet("colors.gray.400"), themeGet("colors.primary"), themeGet("colors.primary"), themeGet("transition"), function (props) {
    return props.$layout === 3 && css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n            a {\n                color: #fff;\n            }\n        "], ["\n            a {\n                color: #fff;\n            }\n        "])));
});
export var BoxLargeImgLink = styled(Anchor)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    visibility: hidden;\n    opacity: 0;\n    text-indent: -99999px;\n"], ["\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    visibility: hidden;\n    opacity: 0;\n    text-indent: -99999px;\n"])));
export var BoxLargeImgWrap = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    transition: ", ";\n    height: 100%;\n    box-shadow: ", ";\n    ", ";\n    \n    &:hover {\n        transform: translateY(-5px);\n        h5 {\n            color: ", ";\n\n            ", "\n        }\n        ", " {\n            visibility: visible;\n            opacity: 1;\n        }\n        ", " {\n            &:before {\n                transform: translateY(0);\n            }\n            a {\n                color: #fff;\n            }\n        }\n    }\n"], ["\n    transition: ", ";\n    height: 100%;\n    box-shadow: ", ";\n    ", ";\n    \n    &:hover {\n        transform: translateY(-5px);\n        h5 {\n            color: ", ";\n\n            ", "\n        }\n        ", " {\n            visibility: visible;\n            opacity: 1;\n        }\n        ", " {\n            &:before {\n                transform: translateY(0);\n            }\n            a {\n                color: #fff;\n            }\n        }\n    }\n"])), themeGet("transition"), themeGet("shadows.default"), space, themeGet("colors.primary"), function (props) {
    return props.$layout === 3 && css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n                    color: #fff;\n                "], ["\n                    color: #fff;\n                "])));
}, BoxLargeImgLink, BoxLargeImgBtnWrap);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
