var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import Text from "@/Components/UI/Text";
import Button from "@/Components/UI/Button";
import Image from "@/Components/UI/Image";
import Heading from "@/Components/UI/Heading";
import { HeroWrapper, StyledBG, HeroTextWrap, AppointmentWrap, AppointmentTitleWrap, AppointmentFormWrap, StyledSubtitle, StyledTitle, } from "./style";
import AppointmentForm from "@/Components/AppointmentForm";
var HeroAreaTwo = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, text = data.text, buttons = data.buttons, image = data.image;
    return (_jsxs(HeroWrapper, { children: [image && (_jsx(StyledBG, { children: _jsx(Image, { src: image, alt: "Appointment" }) })), _jsx(Container, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsxs(HeroTextWrap, { children: [title && (_jsx(StyledSubtitle, __assign({ as: "h6" }, { children: title }))), subTitle && (_jsx(StyledTitle, { as: "h1", dangerouslySetInnerHTML: {
                                            __html: subTitle
                                        } })), text && (_jsx(Text, __assign({ style: { color: '#fff' } }, { children: text }))), buttons === null || buttons === void 0 ? void 0 : buttons.map(function (_a, idx) {
                                        var path = _a.path, content = _a.content, rest = __rest(_a, ["path", "content"]);
                                        return (_jsx(Button, __assign({ mt: "15px", path: path }, rest, { children: content }), idx));
                                    })] }) })), _jsx(Col, __assign({ lg: 5, md: 6, className: "mx-auto" }, { children: _jsxs(AppointmentWrap, { children: [_jsx(AppointmentTitleWrap, { children: _jsx(Heading, __assign({ as: "h5", mb: "10px" }, { children: "Get in touch with us!" })) }), _jsx(AppointmentFormWrap, { children: _jsx(AppointmentForm, {}) })] }) }))] })) })] }));
};
export default HeroAreaTwo;
