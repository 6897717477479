var clients = [
    {
        title: "Danpak Food Industries (Pvt.) Ltd.",
        image: "Clients/danpak.png"
    },
    {
        title: "Sitech Pharmaceuticals (Pvt.) Ltd.",
        image: "Clients/sitech.png"
    },
    {
        title: "ITT Foods (Pvt.) Ltd.",
        image: "Clients/dipitt.png"
    },
    {
        title: "Kings Group",
        image: "Clients/kings.png"
    },
    {
        title: "VSolar (Pvt.) Ltd.",
        image: "Clients/vsolar.png"
    },
    {
        title: "Allied Axiom (Pvt.) Ltd.",
        image: "Clients/allied-axiom.png"
    },
    {
        title: "Zamanat Pakistan",
        image: "Clients/zamanat.png"
    },
    {
        title: "Tech Exons (Pvt.) Ltd.",
        image: "Clients/techexeons.png"
    },
    {
        title: "Premier Mercantile Services (Pvt.) Ltd.",
        image: "Clients/pms.png"
    },
    {
        title: "Noon Academy",
        image: "Clients/noon.png"
    },
    {
        title: "Al Umeed Rehabilitation Association (AURA)",
        image: "Clients/aura.png"
    },
];
export default clients;
