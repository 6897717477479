var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import { BoxLargeImageOne as ITSolutionBox } from "@/Components/BoxLargeImage";
import { SectionWrap } from "./style";
var ServiceAreaOne = function (_a) {
    var data = _a.data, space = _a.space, background = _a.background;
    var services = data.services;
    return (_jsx(SectionWrap, __assign({ "$space": space, "$background": background }, { children: _jsx(Container, { children: services && (_jsx(Row, __assign({ className: "justify-content-center" }, { children: services === null || services === void 0 ? void 0 : services.map(function (service, idx) {
                    return (_jsx(Col, __assign({ className: "box-item", lg: 4, md: 6 }, { children: _jsx(ITSolutionBox, { layout: service.featured ? 3 : 1, title: service.title, desc: service.text, image: service.image, path: service.path }) }), idx));
                }) }))) }) })));
};
ServiceAreaOne.defaultProps = {
    space: 1,
    background: '#fff'
};
export default ServiceAreaOne;
