var menu = [
    {
        id: "menu-1",
        text: "Home",
        path: "/"
    },
    {
        id: "menu-2",
        text: "About Us",
        path: "/about-us"
    },
    {
        id: "menu-3",
        text: "Products",
        subMenu: [
            {
                id: "menu-31",
                text: "Businessflo",
                path: "https://businessflo.com/"
            }
        ]
    },
    {
        id: "menu-4",
        text: "Services",
        subMenu: [
            {
                id: "menu-41",
                text: "Application Development",
                path: "/application-development"
            },
            {
                id: "menu-42",
                text: "Mobile Development",
                path: "/mobile-development"
            }
        ]
    },
    {
        id: "menu-5",
        text: "Clients",
        path: "/clients"
    },
    {
        id: "menu-6",
        text: "Advisors",
        path: "/advisors"
    },
    {
        id: "menu-7",
        text: "Partners",
        path: "/partners"
    },
    {
        id: "menu-8",
        text: "Careers",
        path: "/careers"
    },
    {
        id: "menu-9",
        text: "Events",
        path: "/events"
    },
    {
        id: "menu-10",
        text: "Contact Us",
        path: "/contact-us"
    },
];
export default menu;
