var pageHeader = {
    image: "Banner/banner-01.png",
    title: "About Us",
    text: "Align Business Systems is a leading provider of technological solutions, specializing in custom web development, mobile app development, and our flagship ERP system called Businessflo. With a strong focus on innovation and client satisfaction, we empower businesses with efficient and scalable software solutions."
};
var aboutSection = {
    title: "OUR COMPANY",
    subTitle: "Empower Your Success Journey with Our Next Generation <span>Products & Services</span>",
    text: "Align business systems Pvt Ltd is a organization provides solutions to cater the challenges businesses meets every day but also ensures right consultancy by industry experts so you can scale businesses to grow.<br /><br />We aim to deliver quality services that expand the expectations of our customers and become the best ERP software providers of Pakistan",
    buttons: [
        {
            variant: "texted",
            color: "primary",
            size: "medium",
            shape: "rounded",
            icon: "fas fa-arrow-right",
            icondistance: "8px",
            iconposition: "right",
            disableHover: false,
            hoverStyle: "one",
            fontWeight: 500,
            path: "/contact-us",
            content: "Contact Us"
        }
    ],
    aboutText: "<mark>20</mark> YEARS OF EXPERIENCE"
};
var callToActionSectionOne = {
    title: "Company Profile",
    subTitle: "Get your hands on the power-packed company profile of <span>Align Business Systems!</span> ",
    buttons: [
        {
            content: "Download Now",
            downloadFile: require('@/Assets/Company Profile.pdf').default,
            download: "Company Profile"
        }
    ],
    image: "profile.png"
};
var sectionOne = {
    title: "Unleashing Potential: Our Vision, Mission, and Philosophy",
    subTitle: "Empowering Growth, Innovation, and Excellence in <span>ERP and IT Services<span>",
    items: [
        {
            title: "Our Philosophy",
            text: "At Align Business Systems, we believe in the power of collaboration, integrity, and continuous improvement. We are driven by a customer-centric approach, where we actively listen, understand, and exceed the expectations of our clients. Our philosophy is to embrace change, adapt to evolving technologies, and create a positive impact on businesses and communities we serve.",
            image: "Icons/philosophy.png"
        },
        {
            title: "Our Vision",
            text: "To be a leading organization in the field of ERP and IT services, recognized for delivering innovative solutions that empower businesses to thrive and succeed. We envision a future where technology drives growth, efficiency, and transformation in the business landscape.",
            image: "Icons/vision.png"
        },
        {
            title: "Our Mission",
            text: "Our mission is to provide exceptional ERP and IT services, tailored to the unique needs of each client. We strive to deliver cutting-edge solutions that streamline operations, optimize processes, and enhance business performance. Our focus is on fostering long-term partnerships based on trust, reliability, and continuous innovation.",
            image: "Icons/mission.png"
        },
    ]
};
var sectionTwo = {
    title: "Our Technology",
    subTitle: "Unleashing the Power of <span>Modern Technologies<span>",
    text: "At Align Business Systems, we leverage the latest advancements in technology to revolutionize your business. With expertise in cutting-edge tools like React, ASP.NET, TypeScript, SQL Server, and Crystal Reports, we deliver innovative solutions that elevate your operations to new heights. Experience the transformative power of modern technology with us.",
    image: "Hero/hero-02.png",
    buttons: [
        {
            color: "light",
            content: "ASP. NET Core"
        },
        {
            color: "light",
            content: "REACT JS"
        },
        {
            color: "light",
            hoverStyle: "two",
            content: "ANGULAR"
        },
        {
            color: "light",
            content: "TYPESCRIPT"
        },
        {
            color: "light",
            content: "JAVA"
        },
        {
            color: "light",
            content: "PYTHON"
        },
        {
            color: "light",
            content: "REACT NATIVE"
        },
        {
            color: "light",
            content: "FIGMA"
        },
        {
            color: "light",
            content: "DEVOPS"
        }
    ]
};
var sectionThree = {
    title: "Our Core Values",
    subTitle: "Guiding Principles for <span>Align Business Systems<span>",
    items: [
        {
            title: "Integrity",
            text: "We uphold the highest ethical standards in all our interactions and decisions, fostering trust and transparency among our team members, clients, and stakeholders.",
            icon: "Icons/integrity.png"
        },
        {
            title: "Innovation",
            text: "We embrace a culture of continuous learning and creativity, constantly seeking new ideas, technologies, and solutions to drive innovation and deliver exceptional results.",
            icon: "Icons/innovation.png"
        },
        {
            title: "Client Success",
            text: "We are deeply committed to our clients' success and satisfaction, going above and beyond to understand their unique needs, provide personalized solutions, and exceed their expectations.",
            icon: "Icons/client-success.png"
        },
        {
            title: "Collaboration",
            text: "We are deeply committed to our clients' success and satisfaction, going above and beyond to understand their unique needs, provide personalized solutions, and exceed their expectations.",
            icon: "Icons/collaboration.png"
        },
        {
            title: "Excellence",
            text: "We strive for excellence in everything we do, setting high standards, pursuing continuous improvement, and delivering quality outcomes that make a positive impact.",
            icon: "Icons/excellence.png"
        },
        {
            title: "Accountability",
            text: "We take ownership of our actions, deliver on our commitments, and accept responsibility for our results, fostering a culture of accountability and reliability.",
            icon: "Icons/accountability.png"
        },
    ]
};
var callToActionSectionTwo = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var about = {
    pageHeader: pageHeader,
    aboutSection: aboutSection,
    callToActionSectionOne: callToActionSectionOne,
    callToActionSectionTwo: callToActionSectionTwo,
    sectionOne: sectionOne,
    sectionTwo: sectionTwo,
    sectionThree: sectionThree
};
export default about;
