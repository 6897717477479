var pageHeader = {
    title: "Contact Us",
    path: "/contact-us"
};
var sectionOne = {
    title: "Get in Touch with Us",
    text: "<span>Connect</span> with us for inquiries, support, or partnership opportunities"
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var contactPage = {
    pageHeader: pageHeader,
    sectionOne: sectionOne,
    callToActionSection: callToActionSection
};
export default contactPage;
