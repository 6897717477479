var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { contactPage } from "@/Data";
import Seo from '@/Components/Seo';
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import { PageHeaderTwo } from "@/Components/PageHeader";
import { CallToActionOne } from '@/Components/CallToAction';
import ContactFormArea from '@/Components/ContactFormArea';
var Contact = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: "Contact Us" }), _jsx(Header, {}), _jsxs("main", __assign({ className: "site-wrapper-reveal" }, { children: [_jsx(PageHeaderTwo, { data: contactPage.pageHeader }), _jsx(ContactFormArea, { data: contactPage.sectionOne }), _jsx(CallToActionOne, { data: contactPage.callToActionSection })] })), _jsx(Footer, {})] }));
};
export default Contact;
