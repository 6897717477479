var pageHeader = {
    image: "Banner/banner-05.png",
    title: "Mobile Development",
    text: '"Mobile applications have revolutionized the way we interact and conduct business. In today\'s mobile-first world, having a powerful and engaging mobile app is crucial for businesses to stay competitive, reach a wider audience, and provide exceptional user experiences. At Align Business Systems, we specialize in crafting customized mobile development solutions that bring your ideas to life and empower your business to thrive in the digital era."',
};
var aboutSection = {
    title: "Customized Mobile Application Development Solutions",
    subTitle: "Transforming Your Ideas into <span>Mobile Experiences</span>",
    text: "Step into the world of Align Business Systems, where we redefine the possibilities of mobile technology. As your trusted partner, we embark on a journey together to unlock the full potential of customized mobile application development. We thrive on the challenges presented by the ever-evolving mobile landscape, and our team of experts is relentless in delivering tailored solutions that transcend expectations.\n    Prepare to witness the true transformative power of mobile applications at Align Business Systems. We go beyond the ordinary, diving deep into your vision, understanding your target audience, and embracing your goals as our own. Armed with unrivaled technical prowess and an unyielding commitment to innovation, we craft mesmerizing mobile apps that leave a lasting imprint on\u00A0your\u00A0audience.",
};
var sectionOne = {
    title: "Cutting-Edge Technologies for Mobile Development",
    subTitle: "Harnessing the Power of <span>Mobile Technologies</span>",
    items: [
        {
            icon: "Icons/react.png",
            title: "React Native",
        },
        {
            icon: "Icons/java.png",
            title: "Java",
        },
        {
            icon: "Icons/kotlin.png",
            title: "Kotlin",
        },
        {
            icon: "Icons/mongodb.png",
            title: "MongoDB Realm",
        },
        {
            icon: "Icons/sqllite.png",
            title: "SQLite",
        },
    ],
};
var sectionTwo = {
    title: "Our Mobile Development Expertise",
    subTitle: "Unlocking the <span>Full Potential of Mobile</span> Applications",
    items: [
        {
            title: "iOS App Development",
            text: "We specialize in developing high-quality iOS applications that are intuitive, user-friendly, and optimized for performance. Our team follows best practices and Apple's guidelines to create engaging experiences for iPhone, iPad, and other iOS devices.",
        },
        {
            title: "Android App Development",
            text: "With our expertise in Android development, we deliver feature-rich and visually appealing applications for the vast Android user base. From smartphones to tablets and wearables, we ensure seamless compatibility and optimal performance across various Android devices.",
        },
        {
            title: "Cross-Platform App Development",
            text: "Using cross-platform frameworks like React Native and Flutter, we build cost-effective mobile apps that can run on multiple platforms, including iOS and Android. This approach allows you to reach a wider audience while minimizing development time and costs.",
        },
        {
            title: "Mobile App UI/UX Design",
            text: "Our dedicated UI/UX designers create stunning and intuitive interfaces that enhance user engagement and satisfaction. By focusing on usability, visual appeal, and seamless interactions, we ensure that your mobile app provides an exceptional user experience.",
        },
        {
            title: "Mobile App Testing and QA",
            text: "To ensure the reliability and performance of your mobile app, our experienced QA team conducts comprehensive testing across various devices and scenarios. We rigorously check for bugs, performance issues, and compatibility to deliver a flawless app to your users.",
        },
        {
            title: "App Store Submission and Support",
            text: "We guide you through the app submission process, making it easy to publish your app on the Apple App Store and Google Play Store. Additionally, we provide ongoing support and maintenance to keep your mobile app up-to-date and optimized for the latest OS versions.",
        },
    ],
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var mobileDevelopment = {
    pageHeader: pageHeader,
    aboutSection: aboutSection,
    sectionOne: sectionOne,
    sectionTwo: sectionTwo,
    callToActionSection: callToActionSection,
};
export default mobileDevelopment;
