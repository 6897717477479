var site = {
    titleTemplate: "Align Business Systems",
    description: "Align Business Systems is an innovative IT company specializing in IT development and ERP solutions. We empower businesses across industries with cutting-edge technology to streamline operations, boost productivity, and achieve sustainable growth.",
    siteUrl: "https://alignbsystems.com/",
    image: "landing.png",
    siteLanguage: "en",
    copyright: "Align Business Systems. <a href='https://alignbsystems.com/' target='_blank' rel='noopener noreferrer'>All Rights Reserved.</a>",
    socials: [
        {
            title: "Youtube",
            icon: "fab fa-youtube",
            link: "https://www.youtube.com/@businessflow4004",
        },
        {
            title: "Linkedin",
            icon: "fab fa-linkedin",
            link: "https://www.linkedin.com/company/align-business-systems-private-limited/",
        },
        {
            title: "Facebook",
            icon: "fab fa-facebook",
            link: "https://www.facebook.com/",
        },
    ],
    contact: {
        address: "Suite# 404, Imperial Trade Tower 68-C, \n        <br/> \n        7th Street Jami Commercial, Main Street 11, \n        <br/> \n        D.H.A. Phase 7 Karachi, 75500",
        website: "https://alignbsystems.com/",
        support: {
            email: "support@alignbsystems.com",
            phones: ["+92.318.6944418"],
        },
        sales: {
            email: "sales@alignbsystems.com",
            phones: ["+92.317.3822207", "+92.317.3822206"],
        }
    },
};
export default site;
