var hero = {
    title: "Simplify Your Business Process.",
    subTitle: "Transform Your <span>Business.<span>",
    text: "Experience the ultimate synergy between technology and business success.",
    buttons: [
        {
            path: "/about-us",
            content: "Learn more"
        }
    ],
    image: "Hero/hero-01.png"
};
var aboutSection = {
    title: 'About Us',
    subTitle: 'Empowering Businesses with <span>Innovative Solutions<span>',
    text: "At Align Business Systems, we are dedicated to helping businesses amplify their success through our innovative solutions. With a relentless focus on delivering cutting-edge technology and exceptional services, we empower organizations to thrive in today's fast-paced digital landscape.\n    <br /><br />\n    Our team of experts is driven by the passion to create transformative solutions that drive growth and provide a competitive edge. Through our expertise in software development, mobile app development, and IT consultation, we deliver tailored solutions that cater to your specific needs.\n    <br /><br />\n    We understand the importance of staying ahead of the curve and embracing disruptive technologies to achieve remarkable results. By leveraging our solutions, businesses can optimize their operations, streamline processes, and elevate their performance to new heights.\n    <br /><br />\n    Partner with us to unlock your full potential and embark on a journey of success. Together, we can revolutionize your business and pave the way for extraordinary achievements in the digital era."
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var home = {
    hero: hero,
    aboutSection: aboutSection,
    callToActionSection: callToActionSection
};
export default home;
