var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Row, Col } from "@/Components/UI/Wrapper";
import SectionTitle from "@/Components/UI/SectionTitle";
import Button from "@/Components/UI/Button";
import Image from "@/Components/UI/Image";
import { ResourcesWrapper, LeftBox, StyledBG } from "./style";
var CallToActionTwo = function (_a) {
    var data = _a.data;
    var title = data.title, subTitle = data.subTitle, buttons = data.buttons, image = data.image;
    return (_jsxs(ResourcesWrapper, { children: [_jsx(StyledBG, { children: _jsx(Image, { src: "Background/cta-bg-2.png", alt: "CTA BG" }) }), _jsx(Container, __assign({ fluid: true }, { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(Col, __assign({ lg: { span: 6, order: 1 }, xs: { order: 2, span: 12 } }, { children: _jsxs(LeftBox, { children: [title && (_jsx(SectionTitle, { mb: "30px", textAlign: ["center", null, null, "left"], title: title, subtitle: subTitle })), buttons === null || buttons === void 0 ? void 0 : buttons.map(function (_a, idx) {
                                        var content = _a.content, download = _a.download, rest = __rest(_a, ["content", "download"]);
                                        return (_jsx(Button, __assign({}, rest, { download: download }, { children: content }), idx));
                                    })] }) })), _jsx(Col, __assign({ lg: { span: 6, order: 2 }, xs: { order: 1, span: 12 }, mb: ["30px"], textAlign: ["center"] }, { children: image && (_jsx(Image, { className: "profile", src: image, alt: "Cta" })) }))] })) }))] }));
};
export default CallToActionTwo;
