var pageHeader = {
    image: "Banner/banner-04.png",
    title: "Application Development",
    text: '"Applications are the backbone of modern businesses, enabling them to streamline processes, enhance productivity, and deliver exceptional user experiences. Harnessing the power of customized applications empowers organizations to stay ahead in a rapidly evolving digital landscape and unlock their true potential."'
};
var aboutSection = {
    title: 'Customized Application Development Solutions',
    subTitle: 'Boosting Your Business with cutting-edge <span>Technological Capabilities</span>',
    text: "Welcome to Align Business Systems, where we specialize in providing customized application development solutions to propel your business forward. Our team of experts is dedicated to understanding your unique requirements and crafting applications that perfectly align with your business objectives. At Align Business Systems,We work closely with you to gain a deep understanding of your processes, challenges, and goals. By leveraging the latest technologies and our technical expertise and functional business knowledge , we create innovative applications that optimize your operations, enhance productivity, and drive business\u00A0growth."
};
var sectionOne = {
    title: "Cutting-Edge Technologies for Innovative Solutions",
    subTitle: "Empowering Your Business with <span>Advanced Technological Capabilities</span>",
    items: [
        {
            icon: "Icons/react.png",
            title: "React",
        },
        {
            icon: "Icons/asp.png",
            title: "ASP .Net",
        },
        {
            icon: "Icons/c#.png",
            title: "C#",
        },
        {
            icon: "Icons/typescript.png",
            title: "Typescript",
        },
        {
            icon: "Icons/angular.png",
            title: "Angular",
        },
        {
            icon: "Icons/node.png",
            title: "Node Js",
        },
        {
            icon: "Icons/python.png",
            title: "Python",
        },
        {
            icon: "Icons/sql.png",
            title: "SQL Server",
        },
        {
            icon: "Icons/adobe-xd.png",
            title: "Adobe XD",
        },
        {
            icon: "Icons/figma.png",
            title: "Figma",
        },
    ]
};
var sectionTwo = {
    title: "Our Expertise",
    subTitle: "Unleashing the <span>Power of Custom</span> Application Development",
    items: [
        {
            title: "Desktop Application Development",
            text: "Our team excels in building robust desktop applications tailored to your specific needs. We utilize modern technologies, frameworks, and tools to deliver efficient, feature-rich, and scalable solutions.",
        },
        {
            title: "Web Application Development",
            text: "Harnessing the power of web technologies, we develop dynamic and interactive web applications that provide seamless user experiences. Our web solutions are built using cutting-edge frameworks and ensure cross-browser compatibility and responsive design.",
        },
        {
            title: "Enterprise Application Development",
            text: "We understand the complexities of enterprise-level operations. Our expertise lies in developing comprehensive enterprise applications that integrate with existing systems, automate processes, and streamline workflows, enhancing overall efficiency.",
        },
        {
            title: "Custom Software Development",
            text: "Off-the-shelf software may not always address your unique business requirements. Our custom software development services empower you with tailor-made solutions that align perfectly with your processes, ensuring optimal performance and productivity.",
        },
        {
            title: "User Experience (UX) Design",
            text: "We prioritize user-centric design to deliver intuitive and visually appealing interfaces. Our UX designers focus on creating seamless interactions, intuitive navigation, and delightful user experiences that enhance engagement and satisfaction.",
        },
        {
            title: "Testing and Quality Assurance",
            text: "Ensuring the reliability and functionality of your applications is paramount. Our dedicated testing and quality assurance team conducts rigorous testing to identify and address any issues, delivering high-quality and bug-free applications.",
        },
    ]
};
var callToActionSection = {
    title: "Let’s talk about innovative solutions,<span> business automation </span>and how we can help you achieve your business goals.",
    buttons: [
        {
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Let's talk"
        },
        {
            path: "/about-us",
            color: "light",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "Get Info"
        }
    ]
};
export var applicationDevelopment = {
    pageHeader: pageHeader,
    aboutSection: aboutSection,
    sectionOne: sectionOne,
    sectionTwo: sectionTwo,
    callToActionSection: callToActionSection
};
export default applicationDevelopment;
