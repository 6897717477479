var serviceSection = {
    services: [
        {
            title: "Application Development",
            path: "/application-development",
            image: "Icons/application-development.png",
            text: 'ABS specializes in custom application development services, encompassing web apps and enterprise solutions. Our development technique and process prioritize maintaining timelines , fostering creativity and productivity ensures the efficient delivery of projects,  With a deep understanding of customer needs, we take pride in delivering personalized solutions that are meticulously tailored to their unique requirements.'
        },
        {
            title: "Businessflo",
            featured: true,
            path: "https://businessflo.com/",
            image: 'Icons/erp.png',
            text: 'Businessflo is a robust and feature-rich enterprise resource planning (ERP) software solution, meticulously crafted to cater to the needs of diverse industries including manufacturing, trading, services, and construction. With its seamless finance integration, Businessflo empowers organizations to optimize operational workflows, enhance process efficiency, and achieve sustainable growth.'
        },
        {
            title: "Mobile Development",
            path: "/mobile-development",
            image: 'Icons/mobile-development.png',
            text: 'ABS thrives in the realm of mobile development , offering expertise in delivering innovative native and cross-platform applications for the iOS and Android ecosystems.we employ a user-centric approach, coupled with state-of-the-art technology, to craft seamless and tailored mobile experiences that align precisely with your unique needs. Our dedication to delivering exceptional solutions sets us apart in the industry, ensuring your success in the ever-evolving mobile landscape.'
        }
    ],
};
export default serviceSection;
